export default {
  computed: {
    saleOccuring: function () {
      
      let currentDate = new Date();
      
      // For testing - remove
      //currentDate = new Date('August 19, 2024');

      /* Sale Dates */
      let bfStart = new Date('November 24, 2023');
      let bfEnd = new Date('November 25, 2023');
      let bfExtendStart = new Date('November 25, 2023');
      let bfExtendEnd = new Date('November 27, 2023');
      let cyberStart = new Date('November 27, 2023');
      let cyberEnd = new Date('November 28, 2023');
      let summerStart = new Date('May 31, 2024');
      let summerEnd = new Date('June 3, 2024');

      let aviationStart = new Date('August 19, 2024');
      let aviationEnd = new Date('August 24, 2024');

      if (currentDate >= bfStart && currentDate < bfEnd) {
        return "Black Friday Sale";
      } else if (currentDate >= bfExtendStart && currentDate < bfExtendEnd) {
        return "Extended Black Friday Sale";
      } else if (currentDate >= cyberStart && currentDate < cyberEnd) {
        return "Cyber Monday Sale";
      } else if (currentDate >= summerStart && currentDate < summerEnd) {
        return "Grads & Dads Special";
      } else if (currentDate >= aviationStart && currentDate < aviationEnd) {
        return "Aviation Day Special";
      } else {
        return null;
      }
    }
  },
};